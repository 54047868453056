import React, { useEffect } from 'react';
import './App.css';
import summitai from './summitai.png';  // Import your logo
import { useNavigate } from 'react-router-dom';



function App() {
  //uncomment for snow flakes 
  // useEffect(() => {
  //   createSnowflakes();
  // }, []);

  // const createSnowflakes = () => {
  //   const snowflakesContainer = document.querySelector('.snowflakes-container');
  //   const numSnowflakes = 30;

  //   for (let i = 0; i < numSnowflakes; i++) {
  //     const snowflake = document.createElement('div');
  //     snowflake.className = 'snowflake';
  //     snowflake.style.left = `${Math.random() * 100}vw`;  // Position across the entire width
  //     snowflake.style.animationDuration = `${Math.random() * 6 + 2}s`;  // Vary animation duration
  //     snowflake.style.animationDelay = `${Math.random() * 5}s`;  // Vary animation delay
  //     snowflake.textContent = '❄';  // Set the snowflake character
  //     snowflakesContainer.appendChild(snowflake);
  //   }
  // };
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Perform an action, such as navigating to another page
    console.log('Button clicked');
    navigate('/home');
  };

  return (
    <div className="App">
      {/* <div className="snowflakes-container"></div> */}
        <button className="logo-button" onClick={handleButtonClick}>
          <img src={summitai} className="App-logo pulse-animation" alt="Summit AI logo" />
        </button>
        <p className="pulse-animation">
          SUMMIT AI
        </p>
    
    </div>
  );
}


export default App;
