import React from 'react';
import NavBar from './NavBar';
import './HomePage.css';
import aiImage1 from '../images/ai-image1.png'; // Example image paths
import aiImage2 from '../images/mountain_summit.png'; // Example image paths
import aiImage3 from '../images/ai-image3.png'; // Example image paths

function HomePage() {
  return (
    <div className="HomePage">
      <NavBar />
      <div className="content">
        <h1>Summit AI</h1>
        <div className="section-home">
          <p>At Summit AI, we believe in the endless potential of Artificial Intelligence to transform both the workplace and home life. As we stand at the forefront of this technological revolution, we're excited to harness the power of AI to drive innovation, efficiency, and growth. With the ability to process vast amounts of data, automate tasks, and unlock new insights, AI is poised to revolutionise industries and create new opportunities. We're committed to helping our clients tap into this potential and stay ahead of the curve.</p>
          <img src={aiImage1} alt="AI Concept 1" className="ai-image" />
        </div>
        <div className="section-home reverse">
          <p>What sets us apart is our unique blend of technical expertise and educational training skills. We possesses a deep understanding of the latest AI technologies and methodologies, but also has a talent for communicating complex concepts in a clear and concise manner. This enables us to deliver AI solutions and training that empower our clients to get the most out of their AI investments.</p>
          <img src={aiImage2} alt="AI Concept 3" className="ai-image" />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
