import React from 'react';
import NavBar from './NavBar';
import './AboutPage.css';
import peteImg from '../images/pete_img.png'; // Example image path

function AboutPage() {
  return (
    <div className="AboutPage">
      <NavBar />
      <div className="content">
        <h1>About</h1>
        <div className="section-home">
          <p>We are passionate about working with AI solutions and data. With extensive experience as both industry data scientists and professional educators, we bring a unique perspective to every project. Our commitment is to harness the power of AI and data science to drive innovation and efficiency for our customers.</p>
        </div>
        <h2>Instructors / Consultants</h2>
        <div className="content2">
          <img src={peteImg} alt="Pete" className="ai-image" />
          <p>Hi, I'm Pete! With a Ph.D. in Physics and extensive experience in the data science industry, I specialise in machine learning and AI. As a professional educator in data, I bring a unique blend of practical expertise and teaching acumen. I've applied my skills to solve various real-world challenges, from computer vision and image processing to signal processing and generative AI software solutions.

As a certified AWS Machine Learning Specialist and Microsoft Azure professional, I'm equipped to help organisations unlock the potential of AI. With my expertise in data science and education, I provide tailored solutions to drive innovation and growth.  I'm dedicated to delivering high-quality results to meet your unique needs.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
